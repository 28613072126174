import React from 'react';

import events from 'events.js';

export default class FocusManager extends React.Component {
    componentDidMount() {
        events.listen(document, 'mousedown', this.handleMouseDown);
        events.listen(document, 'keydown', this.handleKeyDown);
        events.listen(document, 'touchstart', this.handleTouch);
    }

    componentWillUnmount() {
        events.unlisten(document, 'mousedown', this.handleMouseDown);
        events.unlisten(document, 'keydown', this.handleKeyDown);
        events.unlisten(document, 'touchstart', this.handleTouch);
    }

    handleMouseDown() {
        if (document.body.classList.contains('key-controlled')) {
            document.body.classList.remove('key-controlled');
        }
    }

    handleKeyDown() {
        if (!document.body.classList.contains('key-controlled')) {
            document.body.classList.add('key-controlled');
        }
    }

    render() {
        return null;
    }
}
